import React from 'react';
import type {GetServerSideProps, NextPage} from 'next';
import {Content, ContentProps} from 'COMPONENTS/content/Content';
import {Components} from 'TYPES/types';
import {MetaHead} from 'COMPONENTS/content/meta/MetaHead';
import {ParsedUrlQuery} from 'querystring';
import ErrorPage from 'COMPONENTS/pages-components/error-page/ErrorPage';
import Footer from 'COMPONENTS/pages-components/common/footer/Footer';
import {Sprite} from 'COMPONENTS/pages-components/common/Icon/Sprite';
import Header from 'COMPONENTS/pages-components/common/header/Header';
import {Cookies} from 'COMPONENTS/pages-components/common/cookies/Cookies';

export type Props = {
  data: ContentProps;
  params?: ParsedUrlQuery;
  statusCode?: string | number;
} & Components;

export const getServerSideProps: GetServerSideProps<Props> = async ({
  params
}) => {
  const baseUrl = process.env.NEXT_PUBLIC_API || '/api-back/api';
  let url = '/';
  if (params && 'slug' in params) {
    const slug = params.slug;
    if (slug) {
      url += Array.isArray(slug) ? slug.join('/') : slug;
    }
  }
  try {
    const [
      componentRes,
      dataRes
    ] = await Promise.all([
      await fetch(`${baseUrl}/components`),
      await fetch(`${baseUrl}${url}`)
    ])
    const data = await dataRes.json() as ContentProps;
    const components = await componentRes.json() as Components;

    return { //
      props: {
        data: data,
        params,
        ...components
      }
    };
  } catch (ex) {
    return {notFound: true}
  }
}

const Page: NextPage<Props> = ({data, header, ...props}) => {
// const Page: NextPage<Props> = ({data, ...props}) => {
  if (props.statusCode || !data || !data.items) {
    return <ErrorPage statusCode={props.statusCode || 404}/>
  }

  // console.log(contents)
  // const isMain = !props.params || !props.params.slug || !Array.isArray(props.params.slug) || !props.params.slug[0];

  return <>
    <MetaHead data={data.meta}/>
    <Cookies/>
    <Header {...header}/>
    <Content {...data}/>
    <Footer {...header.ftr}/>
    <Sprite/>
  </>
}

// Если не нужно ни SSR, ни статика, нужно получать дату в useEffect в самом компоненте

// Это для SSR
// export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
//   // const data = await http<ContentProps>({
//   //   action: api + '/main'
//   // })
//   try {
//     const res = await fetch(api + '/main')
//     const data = await res.json() as ContentProps;
//
//     return { props: { data } }
//   } catch (e) {
//     console.log(e);
//     return {props: {data: {items: []}}}
//   }
// }

// Это для статической генерации страниц(все страницы, кроме каталога)
// Никогда не используйте внутренний api next.js - для остального всегда можно
// export const getStaticProps: GetStaticProps<Props> = async ({params}) => {
//   try {
//     const data = await getData(params);
//     const components = await getComponents();
//     let revalidate;
//     if (process.env.REVALIDATE_TIMER) {
//       revalidate = parseFloat(process.env.REVALIDATE_TIMER);
//     }
//     return {
//       props: {data, params, ...components},
//       revalidate
//     };
//   } catch (e) {
//     let statusCode: string | number | undefined = 404;
//
//     if (typeof e === 'string') {
//       statusCode = e;
//     } else if (e instanceof Response) {
//       statusCode = e.status;
//     }
//
//     return {props: {data: {}, header: {}, statusCode}};
//   }
// }
//
// export const getStaticPaths: GetStaticPaths = async () => {
//   let paths: Pathname[] = [];
//
//   try {
//     paths = await getPathnames();
//   } catch (e) {
//     console.warn('PATH_NAMES_ERROR', e);
//   }
//   return {
//     paths: paths.map((path) => path.pathname),
//     fallback: true
//   }
// }

export default Page;
